.root {
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 375px;
}

.container {
  /* width: 375px; */
  width: 100%;

  min-width: 375px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  min-height: 100vh;
  /* overflow-x: auto; */
}

/*@media screen and (max-width: 500px) {
  .container {
    width: 100%;
  }
}*/
