$assetsIcon: '/assets/icons/';

.customScrollbarsContainer {
  // background-color: #733e27;
  // border: 1px solid #333;
  // border-radius: 12px;
  display: flex;
  // height: 300px;
  position: relative;
}

.customScrollbarsContent {
  overflow: auto;
  flex-grow: 1;
}

.customScrollbarsContent::-webkit-scrollbar {
  display: none;
}

.customScrollbarsTrackAndThumb {
  display: block;
  height: 100%;
  position: absolute;
  width: 0px;
  right: 7px;
}

.customScrollbarsTrack {
  background-image: linear-gradient(to right, #7a5b41, #4d2a19);
  border-left: 1px solid #ffffff;
  border-radius: 12px;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 3px;
  border-radius: 20px;
  left: 6px;
}

.customScrollbarsThumb {
  position: absolute;
  width: 16px;
  background-image: url(#{$assetsIcon}/scroll-thumb.svg);
}