.container {
    display: block;
    position: relative;
    padding-left: 35px;
    height: 30px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 31.25px;
    width: 29.84px;
    background-image: url('src/assets/icons/checkbox/un-checkbox.svg');
}

.container input[type=radio]:checked~.checkmark {
    background-image: url('src/assets/icons/checkbox/checkbox.svg');
}

.container input[type=checkbox]:checked~.checkmark {
    background-image: url('src/assets/icons/checkbox/checkbox.svg');
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmark:after {
    display: block;
}
