@import '../../../../public/styles/common';

$assetsIconPath: '/assets/icons';

.customDatePicker {
  border-radius: 20px !important;
  background-image: url(#{$assetsIconPath}/dropdown/dropdown.svg) !important;
  background-size: cover !important;
  background-position: center !important;
  height: 34px !important;
  width: 222px !important;
  border: none !important;
  padding: 0px 15px !important;
  box-sizing: border-box !important;
  font-family: 'DFMRG9' !important;
  color: #833e29 !important;
  font-weight: 400 !important;
  padding-bottom: 6px !important;
}

:global {
  .ant-picker-input > input::placeholder {
    color: #833e29 !important;
  }
  .ant-picker-input > input {
    color: #833e29 !important;
    font-size: 11px !important;
  }
  .ant-input::placeholder {
    color: #833e29 !important;
    font-size: 11px !important;
  }
  .ant-input-focused,
  .ant-input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.customInput {
  border-radius: 20px !important;
  background-image: url(#{$assetsIconPath}/dropdown/dropdown.svg) !important;
  background-size: cover !important;
  background-position: center !important;
  height: 34px !important;
  width: 222px !important;
  border: none !important;
  padding: 0px 15px !important;
  box-sizing: border-box !important;
  font-family: 'DFMRG9' !important;
  color: #833e29 !important;
  font-weight: 400 !important;
  padding-bottom: 8px !important;
  outline: none;
  font-size: 11px;
  &::placeholder {
    color: #833e29 !important;
    font-size: 11px !important;
  }
}

.customInputPassword {
  border-radius: 20px;
  background-image: url(#{$assetsIconPath}/dropdown/dropdown.svg) !important;
  background-size: cover;
  background-position: center;
  height: 34px;
  width: 222px;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.field {
  flex-grow: 1;
  border: none;
  background: transparent;
  color: #833e29;
  outline: none;
  margin-left: 15px;
  font-family: 'DFMRG9';
  font-weight: 400;
  font-size: 11px;
  & :focus-visible {
    outline: none;
  }
}

.icon {
  min-width: 13px;
  height: 13px;
  margin: 0px 15px;
}


.error {
  color: $error-color;
  font-size: 11px;
  margin-top: 0px !important;
  width: 222px;
  padding-left: 10px;
}