@import '../../../../public/styles/common';

$assetPath :"/assets/icons/dropdown/";

.rootBoxCustom {
  display: flex;
  flex-wrap: wrap;
  width: 222px;
  * {
    font-family: $base__font-family;
    color: #833e29;
    font-weight: 400;
    font-size: 11px;
  }
}

.boxSelect {
  width: 100%;
  height: 34px;
  display: flex;
  background-image: url(#{$assetPath}/dropdown.svg);
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  justify-content: space-between;
  padding: 0px 15px;
  border: none;
  width: 100%;
  cursor: pointer;

  line-height: 27px;
}

.iconArrow {
  margin-left: 10px;
  transition: all 0.5s ease 0s;
}

.boxArrow:focus {
  outline: none;
}

.children {
  position: relative;
  width: 100%;
}

.boxUl {
  background-color: #fadeb1;
  position: absolute;
  list-style-type: none;
  z-index: 1;
  padding: 10px;
  border-radius: 10px;
  margin-top: 5px;
  width: 100%;
  border: 1px solid #833e294d;
  box-sizing: border-box;
  li > button {
    background-color: #fadeb1;
    border: none;
    border-bottom: 1px solid #833e294d;
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
  }
  li > button:hover {
    background-color: #833e294d;
    color: #ffffff;
  }
}

// .boxUl>li>button {
//     background-color: #FADEB1;
//     border: none;
//     border-bottom: 1px solid #833e294d;
//     padding: 5px;
//     box-sizing: border-box;
//     width: 100%;
//     cursor: pointer;

// }

// .boxUl>li>button:hover {
//     background-color: #833e294d;
//     color: #ffffff;
// }
