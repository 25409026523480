
$assetIconPath: '/assets/icons/';

.avatarCircle {
  background-position: center;
  background-size: cover;
  height: 62px;
  width: 54px;
  position: relative;
}

.blue {
  background-image: url(#{$assetIconPath}/avatar/avatar-blue.svg);
}

.pink {
  background-image: url(#{$assetIconPath}/avatar/avatar-pink.svg);
}

.img {
  position: absolute;
  width: 44px;
  height: auto;
  top: 18px;
  left: 4px;
  border-radius: 50%;
}

.bow {
  position: absolute;
  top: 0;
  left: 8px;
  z-index: 2;
  width: 30px;
}

.petCard {
  background-position: center;
  background-size: cover;
  height: 89.45px;
  width: 89.19px;
  position: relative;
}

.petCardSmall {
  background-position: center;
  background-size: cover;
  height: 45.92px;
  width: 45.92px;
  position: relative;
}

.imgPetCard {
  position: absolute;
  width: 53px;
  height: auto;
  top: 18px;
  left: 22px;
}

.imgPetCardSmall {
  position: absolute;
  width: 27px;
  height: auto;
  top: 10px;
  left: 12px;
}

.bowPetCard {
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 2;
  width: 37px;
}
.bowPetCardSmall {
  position: absolute;
  top: 0;
  left: -2px;
  z-index: 2;
  width: 22px;
}

.bluePetCard {
  background-image: url(#{$assetIconPath}/avatar/pet-card-blue.svg);
}

.pinkPetCard {
  background-image: url(#{$assetIconPath}/avatar/pet-card-pink.svg);
}


.petCard1 {
  height: 101px;
  width: 101px;
  position: relative;
}

.background {
  position: absolute;
  z-index: 1;
}

.backgroundBlueBallon {
  background-color: #fff;
  height: 81px;
  width: 81px;
  left: 9px;
  top: 12px;
}

.imgFrame {
  position: absolute;
  width: 60px;
  height: auto;
  z-index: 2;
}
.imgFrameBlueBallon {
  bottom: 14px;
  left: 17px;
}

.imgFramePinkStar {
  bottom: 12px;
  left: 22px;
}

.backgroundPinkStar  {
  background-color: #fff;
  height: 80px;
  width: 80px;
  left: 11px;
  top: 15px;
}

.imgFrameRedHeart {
  bottom: 12px;
  left: 22px;
}

.backgroundRedHeart  {
  background-color: #FFD997;
  height: 79px;
  width: 78px;
  left: 10px;
  top: 17px;
}

.frame {
  position: absolute;
  z-index: 3;
}