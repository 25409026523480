/********************** Color **********************/
// Theme
@use 'sass:math';

$primary: #833e29;

$dark: #00165933;

$color-white: #ffffff;
$color-brown: #6a3906;
$color-orange: #f39200;
$error-color: #e63946;

$color-ivory-white: #b99b7b;
$color-1: #ffe5c9;
$color-2: #6b1b00;
$color-3: #ff4c72;
$color-4: #932c11;
$color-5: #f29600;
$color-6: #f0f0f0;
$color-7: #8f3200;
$color-8: #666666;
$color-9: #c9bc9c;
$color-10: #f9e8c2;
$color-11: #f7f7f7;
$color-12: #6e6e6e;
$color-13: #d26378;
$color-14: #fadeb1;
$color-15: #833e294d;
$color-16: #c79f62;
$color-17: #3a1202;
$color-18: #fffdee;
$color-19: #fff3dd;
$color-20: #000000;
$color-21: #796a56;
$color-22: #f8e8de;
$color-23: #6f6763;
$color-24: #d9b6a1;
$color-25: #706763;
$color-26: #989898;
$color-27: #ccc;
$color-28: #e5cabb;
$color-29: #e78959;
$color-30: #6a3906;
/********************** Typography **********************/
$base__font-family: 'DFMRG9' !important;
