#switchButton,
#circle {
    -webkit-transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
    transition: all 0.4s cubic-bezier(0.54, 1.6, 0.5, 1);
}

#switchButton {
    width: 30px;
    border: none;
    background: transparent;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 19px;
    background-image: url('src/assets/icons/switch-button/toggle.svg');
    background-position: center;
    background-size: cover;
    padding: 0px;
    cursor: pointer;
}

#circle {
    width: 14px;
    height: 14px;
    background-image: url('src/assets/icons/switch-button/red-dot.svg');
    background-position: center;
    background-size: cover;
    margin-left: 2px;
    margin-top: -1px;
}

#switchButton:focus {
    outline: none;
}

.switched #circle {
    margin-left: 47%;
    background-image: url('src/assets/icons/switch-button/green-dot.svg');
}