.button {
  border: none !important;
  padding: 0 !important;
  background: transparent !important;
  width: fit-content !important;
  height: fit-content !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
:global {
  [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}
