$essetsImg: '/assets/images/';

.container {
  width: 100%;
  min-height: 500px;
  height: 100vh;
  background-image: url(#{$essetsImg}/pet-paradise.png);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.boxImg {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.studio {
  position: absolute;
  bottom: 81px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
