$essetsImg: '/assets/images/';

.boxProgess {
  position: absolute;
  background: linear-gradient(180deg, #aabdc4 -22.5%, #ffffff 100%);
  border-radius: 200px;
  height: 18px;
  width: calc(100% - 58px);
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #ffffff 15%, #aabdc4 135%);
  bottom: 10%;
}

.progess {
  background-image: url(#{$essetsImg}/progess.png);
  height: 12px;
  border-radius: 200px;
  margin: 3px;
}
