@import '../../../../../public/styles/common';

$assetImgPath: '/assets/images/';
$assetIconPath: '/assets/icons/';

.ParcitipationForm {
  background-color: $color-16;
  padding: 7px 4px;
  p {
    margin: 0;
    // word-break: keep-all;
    font-family: $base__font-family;
  }
}

.title {
  color: $color-white;
  text-align: center;
  margin-bottom: 10px !important;
}

.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-family: $base__font-family;
    font-size: 9px;
    margin-bottom: 0px;
    text-align: center;
    color: $color-17;
    margin: 10px 0px;
  }
}


.error {
  color: $error-color;
  font-size: 11px;
  margin-top: 0px !important;
  width: 222px;
  padding-left: 10px;
}

.disable {
  filter: grayscale(1);
  opacity: 1;
  cursor: not-allowed;
}