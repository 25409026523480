.boxLoading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  z-index: 999;
  align-items: center;
  background-color: #00000047;
}
