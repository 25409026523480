@import '../../../../../public/styles/common';

$assetImgPath: '/assets/images/';
$assetIconPath: '/assets/icons/';

.boxVote {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
  background-image: url(#{$assetIconPath}/camera/background.svg);
  min-height: 286px;
  p {
    margin: 0;
    font-family: 'DFMRG9' !important;
    color: #6a3906;
    text-align: center;
  }
}

.head {
  width: 170px;
  padding: 6px 13.5px;
  background-color: $color-white;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.box {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 101px;
  padding-top: 10px;
  margin-top: 10px;
}

.name {
  background-image: url(#{$assetIconPath}/camera/frame-1.svg);
  width: 101.7px;
  height: 28.8px;
  position: absolute;
  top: -10px;
  & > p:first-child {
    color: $color-white;
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: 0px;
    min-width: 25px;
    text-align: center;
  }
  & > p:last-child {
    color: $color-brown;
    font-size: 12px;
    position: absolute;
    top: 6px;
    left: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
}
.img {
  margin-bottom: 5px;
  width: 86px;
  height: 86px;
}

.disable {
  filter: grayscale(1);
  opacity: 1;
  cursor: not-allowed;
}
