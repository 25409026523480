@import '../../../../public/styles/common';

$assetsIcon: '/assets/icons/';

.uploadAvatar {
  position: relative;
}

.flexJustifyCenter {
  display: flex;
  justify-content: center;
}

.boxUpload {
  position: absolute;
  width: 100%;
  height: 160px;
  top: 0;
}

.boxUpload > input {
  display: none !important;
}

.boxUpload > label > div {
  cursor: pointer;
  width: 160px;
  height: 160px;
}

.boxImage {
  flex-direction: column;
  align-items: center;
}

.boxImage > input[type='range'] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 300px;

  background: transparent;
}

.boxImage > input[type='range']:focus {
  outline: none;
}

.boxImage > input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  background-color: #733e27;
  border: 1px solid #333;
  border-radius: 12px;
  border: 0.2px solid #010101;
}

.boxImage > input[type='range']::-webkit-slider-thumb {
  height: 11px;
  width: 16px;
  background-image: url(#{$assetsIcon}/scroll-thumb.svg);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}

.boxImage > input[type='range']:focus::-webkit-slider-runnable-track {
  background: #733e27;
}

.boxImage > input[type='range']::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background-color: #733e27;
  border: 1px solid #333;
  border-radius: 12px;
  border: 0.2px solid #010101;
}

.boxImage > input[type='range']::-moz-range-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px; */
  height: 11px;
  width: 16px;
  background-image: url(#{$assetsIcon}/scroll-thumb.svg);
  /* border-radius: 3px; */
  /* background: #ffffff; */
  cursor: pointer;
}

.boxImage > input[type='range']::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

.boxImage > input[type='range']::-ms-fill-lower {
  background-color: #733e27;
  /* border: 1px solid #333; */
  border: 0.2px solid #333;
  border-radius: 12px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

.boxImage > input[type='range']::-ms-fill-upper {
  background-color: #733e27;
  /* border: 1px solid #333; */
  border: 0.2px solid #333;
  border-radius: 12px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

.boxImage > input[type='range']::-ms-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px; */
  /* border-radius: 3px;
    background: #ffffff; */
  cursor: pointer;
  height: 11px;
  width: 16px;
  background-image: url(#{$assetsIcon}/scroll-thumb.svg);
}

.boxImage > input[type='range']:focus::-ms-fill-lower {
  background-color: #733e27;
  border: 1px solid #333;
}

.boxImage > input[type='range']:focus::-ms-fill-upper {
  background-color: #733e27;
  border: 1px solid #333;
}

.canvas {
  border-radius: 10px;
}

.boxModal {
 
  :global {
    .ant-modal-content {
      padding: 10px 5px !important;
      background-color: $color-14;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid $color-15;
    }
    .ant-modal-body {
      padding: 0;
      overflow-y: hidden !important;
    }
  }
}
