@import '../../public/styles/common';

$assetImgPath: '/assets/images/';
$assetIconPath: '/assets/icons/';

.dashboard {
  width: 100%;
  min-height: 100%;
  background-image: url(#{$assetImgPath}/background-2.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.container {
  position: relative;
}

.footer {
  position: relative;

  .imgWoodBoard {
    width: 285px;
    margin-bottom: 15px;
  }
  p {
    position: absolute;
    text-align: center;
    font-size: 12px;
    font-family: $base__font-family;
    font-weight: 400;
    color: $primary;
    top: 28px;
    width: 240px;
    left: calc(50% - 120px);
  }
}

.buttonSetting {
  position: absolute !important;
  top: -12vh;
}
