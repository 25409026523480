@import '../../../../public/styles/common';

$assetsIcon: '/assets/icons/';

.answer {
  border-radius: 8px;
  background-color: $color-white;
  min-height: 48px;
  padding: 11px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  display: block;
  position: relative;
  padding-left: 37px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  p {
    margin: 0;
    font-family: $base__font-family;
    font-weight: 400;
  }
}

.larger {
  width: 100%;
}

.medium {
  width: calc(50% - 6px);
}

.text {
  font-size: 14px;
  color: $color-25;
  margin-right: 8px !important;
}

.answer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: calc(50% - 10px);
  left: 12px;
  height: 20px;
  width: 20px;
  background-color: $color-white;
  border-radius: 50%;
  border: 2px solid $color-26;
}

.answer:hover input ~ .checkmark {
  background-color: $color-27;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.answer input:checked ~ .checkmark:after {
  display: block;
}

.answer .checkmark:after {
  top: 3px;
  left: 3px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: $color-26;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
  height: 100%;
}

.justCenter {
  justify-content: center;
}

.border {
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  border: 1.5px solid $color-24;
  border-radius: 8px;
}

.answer input:checked ~ .border {
  border: 1.5px solid $color-23;
}
