@import '../../../../../public/styles/common';

$assetImgPath: '/assets/images/';
$assetIconPath: '/assets/icons/';

.detailsEvent {
  background-color: $color-white;
}

.boxImg {
  position: relative;
}

.img {
  width: 100%;
  height: auto;
}

.imgTitle {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 7px;
  left: 0;
  div {
    font-family: $base__font-family;
    font-weight: 400;
    color: $color-brown;
    background: $color-white;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 6px;
    font-size: 14px;
    width: 177px;
    word-break: keep-all;
  }
}

.introEvent {
  padding: 8px;
  p {
    margin: 0;
    font-family: $base__font-family;
    font-weight: 400;
  }
}

.boxButton {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}