@import '../../../../../public/styles/common';

$assetImgPath: '/assets/images/';
$assetIconPath: '/assets/icons/';

.boxCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  p {
    margin: 0;
    font-family: $base__font-family;
  }
}

.contentCard {
  width: 243px;
}

.head {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
}

.cameraIcon {
  background-image: url(#{$assetIconPath}/camera/camera-1.svg);
  width: 32.54px;
  height: 32.54px;
  margin-left: 4px;
  margin-top: 4px;
}

.underRecruitment {
  background-image: url(#{$assetIconPath}/camera/under-recruitment.svg);
  width: 36.54px;
  height: 36.54px;
  margin-left: -4px;
  margin-top: -4px;
}

.title {
  font-family: $base__font-family;
  font-weight: 400;
  color: $color-brown;
  background: $color-white;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 6px;
  font-size: 14px;
  width: 177px;
  word-break: keep-all;
  margin-left: 12px;
  margin-top: 8px;
}

.solid1 {
  background-image: url(#{$assetIconPath}/camera/content-card-2.svg);
  width: 100%;
  height: 16px;
}

.solid2 {
  background-image: url(#{$assetIconPath}/camera/content-card-1.svg);
  width: 100%;
  height: 16px;
}

.content {
  display: flex;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
}

.time {
  font-size: 6px;
  font-weight: bold;
  margin-left: 8px !important;
  width: 40px;
}

.btnColor8 {
  color: $color-8;
}

.btnColorWhite {
  color: $color-white;
}

.btnBackgroundColor9 {
  background-color: $color-9;
}

.btnBackgroundColor6 {
  background-color: $color-6;
}

.substance {
  font-size: 8px;
  font-weight: bold;
  width: 160px;
}

.boxButton {
  display: flex;
  justify-content: center;
  margin-top: 6px;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
}

.vote {
  margin-right: 63px;
}

.description {
  display: flex;
  justify-content: space-evenly;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
